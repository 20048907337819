import { RiChat1Line } from "react-icons/ri";
import { useNavigate } from "react-router";
import {
  Box,
  Flex,
  FlexProps,
  Progress,
  Text,
  Tooltip,
} from "@chakra-ui/react";

import {
  FeatureList,
  FeatureListColumn,
  FeatureListItem,
  FeatureListSortBy,
} from "@bucketco/shared/featureAPI";
import { FeatureViewDTO } from "@bucketco/shared/featureViewAPI";
import { FeatureAnalyzeUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AutoFeedbackSurveys from "@/common/assets/auto-feedback-surveys-icon.svg?react";
import CompactNumber from "@/common/components/CompactNumber";
import DataCell from "@/common/components/DataCell";
import { DataTable } from "@/common/components/DataTable/DataTable";
import { FrequencyProgress } from "@/common/components/FrequencyProgress";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import PercentageNumber from "@/common/components/PercentageNumber";
import { SatisfactionRateProgress } from "@/common/components/SatisfactionRateProgress";
import TimestampCell from "@/common/components/TimestampCell";
import { useSafeDataTableStateProps } from "@/common/hooks/useDataTableParamState";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import { linkClickModifierHandler } from "@/common/utils/linkClickHandler";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { FeatureNameCell } from "@/feature/components/FeatureNameCell";
import { FeaturesEmptyState } from "@/feature/components/FeaturesEmptyState";
import { FeatureSourceIcon } from "@/feature/components/FeatureSourceIcon";
import { GoalCell } from "@/feature/components/Goals/GoalCell";
import { RulesSummary } from "@/feature/components/RolloutStatus";
import { NoDataYet } from "@/feature/components/RolloutStatusTag";
import { StarsRateLabel } from "@/feature/components/StarsRateLabel";
import { TableDisplayModePicker } from "@/feature/components/TableDisplayModePicker";
import { useFeaturesSearch } from "@/feature/hooks/useFeaturesSearch";
import { useFeaturesTableState } from "@/feature/hooks/useFeaturesTableState";
import { useStarsFunnelColors } from "../hooks/useStarsFunnelColors";

const defaultColumns: Array<FeatureListColumn> = [
  "name",
  "rolloutEnvironment",
  "rolloutTargetingRules",
  "goals",
  "createdAt",
];

type Props = {
  view: FeatureViewDTO;
  data?: FeatureList;
  isLoading?: boolean;
  isFetching?: boolean;
  subsegment?: string | string[];
};

export function FeaturesTable({
  view,
  data,
  isLoading,
  isFetching,
  subsegment,
}: Props) {
  const { currentEnv } = useAuthContext();
  const navigate = useNavigate();

  const tableState = useFeaturesTableState(view);
  // We don't want to pass along invalid props to the data table
  const safeTableProps = useSafeDataTableStateProps(tableState);

  const features = data?.data ?? [];
  const filteredFeatures = useFeaturesSearch(
    features,
    {
      enabled: true,
      predicate: (f) => {
        return (
          f.id === tableState.searchQuery ||
          f.key.toLowerCase().includes(tableState.searchQuery.toLowerCase()) ||
          f.name.toLowerCase().includes(tableState.searchQuery.toLowerCase())
        );
      },
    },
    [tableState.searchQuery],
  );

  return (
    <DataTable
      columns={columns}
      data={filteredFeatures}
      defaultColumns={defaultColumns}
      emptyState={<FeaturesEmptyState isLoading={isLoading} view={view} />}
      isFetching={isFetching}
      meta={data?.metadata}
      searchPlaceholder="Search by name, key, or ID"
      tableId="features"
      toolbarRightActions={<TableDisplayModePicker />}
      variant="clickable"
      canCustomize
      canSearch
      canSort
      hasPersistedState
      onRowClick={(row, e) => {
        const url = FeatureAnalyzeUrl(currentEnv!, row.original, {
          subsegment,
        });
        linkClickModifierHandler(url, (e) => {
          e.preventDefault();
          navigate(url);
        })(e);
      }}
      {...safeTableProps}
    />
  );
}

const MultiValueFlexContainer = ({
  children,
  ...props
}: React.PropsWithChildren<FlexProps>) => (
  <Flex
    alignItems="center"
    fontSize="sm"
    gap={2}
    whiteSpace="nowrap"
    {...props}
  >
    {children}
  </Flex>
);

const CellDisplayCount = ({
  percentage,
  count,
  color,
  ...rest
}: FlexProps & {
  percentage: number | null;
  count?: number | null;
  color?: string;
}) => {
  if (percentage === null) {
    return <NotAvailableCell />;
  }

  return (
    <MultiValueFlexContainer {...rest}>
      <Progress
        aria-hidden={true}
        color={color}
        max={1}
        min={0}
        minWidth={"60px"}
        size="sm"
        value={percentage}
      />
      <Box minW="3em" textAlign="right">
        <PercentageNumber value={percentage} />
      </Box>
      {typeof count == "number" && (
        <Box color="dimmed" minW="32px">
          <CompactNumber value={count} />
        </Box>
      )}
    </MultiValueFlexContainer>
  );
};

const CellDisplayRate = ({
  rate,
  color,
  ...rest
}: FlexProps & {
  rate: number | null;
  color?: string;
}) => {
  if (rate === null) {
    return <NotAvailableCell />;
  }

  return (
    <MultiValueFlexContainer {...rest}>
      <Progress
        aria-hidden={true}
        color={color}
        max={1}
        min={0}
        minWidth={"60px"}
        size="sm"
        value={rate}
      />
      <Box minW="72px">
        <StarsRateLabel rate={rate} />
      </Box>
    </MultiValueFlexContainer>
  );
};

const CellDisplayText = ({
  text,
  icon,
  tooltip,
  textColor,
  iconColor,
  ...rest
}: FlexProps & {
  text: string | JSX.Element | null;
  icon?: JSX.Element;
  tooltip?: string;
  textColor?: string;
  iconColor?: string;
}) => {
  if (!text) {
    return <NotAvailableCell />;
  }
  return (
    <MultiValueFlexContainer gap={1.5} {...rest}>
      <Box color={iconColor ?? "dimmed"}>{icon}</Box>
      <Tooltip label={tooltip} openDelay={300} hasArrow>
        <Text color={textColor}>{text}</Text>
      </Tooltip>
    </MultiValueFlexContainer>
  );
};

type FeatureListHeaderType = string; // Headers must be strings to support Slack digests

const columnHelper = createSortableColumnHelper<
  FeatureListItem,
  FeatureListSortBy,
  FeatureListHeaderType
>();

export const columns = [
  columnHelper.accessor("name", {
    header: "Name",
    cell: FeatureNameCell,
    maxSize: document.body.clientWidth / 4,
  }),
  columnHelper.accessor("key", {
    header: "Key",
    cell: (cell) => {
      const value = cell.getValue();
      return <DataCell type="string" value={value} />;
    },
  }),
  columnHelper.accessor("rolloutEnvironment", {
    header: "Environment status",
    cell: function RolloutStatusRenderer(cell) {
      const value = cell.getValue();
      if (value == null) {
        return <NoDataYet fontSize="sm" />;
      }
      return <EnvironmentDisplayName environment={value} fontSize="sm" />;
    },
  }),
  columnHelper.accessor("rolloutTargetingRules", {
    header: "Targeting (Production)",
    enableSorting: false,
    cell: function RolloutTargetingRulesRenderer(cell) {
      const value = cell.getValue();
      return <RulesSummary fontSize="sm" rules={value} />;
    },
  }),
  columnHelper.accessor("goals", {
    header: "Goals",
    cell: function TotalGoalProgressRenderer(cell) {
      const value = cell.getValue();
      return <GoalCell goals={value} max={4} maxWithDescription={2} />;
    },
  }),
  columnHelper.accessor("triedCompaniesFraction", {
    header: "Tried",
    cell: function TriedCompaniesFractionRenderer(cell) {
      const value = cell.getValue();
      const { currentMetrics } = cell.row.original;
      const { triedColor } = useStarsFunnelColors();
      return (
        <CellDisplayCount
          color={triedColor}
          count={currentMetrics?.tried}
          percentage={value}
        />
      );
    },
  }),
  columnHelper.accessor("adoptedCompaniesFraction", {
    header: "Adopted",
    cell: function AdoptedCompaniesFractionRenderer(cell) {
      const value = cell.getValue();
      const { currentMetrics } = cell.row.original;
      const { adoptedColor } = useStarsFunnelColors();
      return (
        <CellDisplayCount
          color={adoptedColor}
          count={currentMetrics?.adopted}
          percentage={value}
        />
      );
    },
  }),
  columnHelper.accessor("retainedCompaniesFraction", {
    header: "Retained",
    cell: function RetainedCompaniesFractionRenderer(cell) {
      const value = cell.getValue();
      const { currentMetrics } = cell.row.original;
      const { retainedColor } = useStarsFunnelColors();
      return (
        <CellDisplayCount
          color={retainedColor}
          count={currentMetrics?.retained}
          percentage={value}
        />
      );
    },
  }),
  columnHelper.accessor("satisfiedCompaniesFraction", {
    header: "Satisfied",
    cell: function SatisfiedCompaniesFractionRenderer(cell) {
      const value = cell.getValue();
      const { satisfiedColor } = useStarsFunnelColors();
      return <CellDisplayCount color={satisfiedColor} percentage={value} />;
    },
  }),
  columnHelper.accessor("adoptionRate", {
    header: "Adoption rate",
    cell: function AdoptionRateRenderer(cell) {
      const value = cell.getValue();
      const { adoptedColor } = useStarsFunnelColors();
      return <CellDisplayRate color={adoptedColor} rate={value} />;
    },
  }),
  columnHelper.accessor("retentionRate", {
    header: "Retention rate",
    cell: function RetentionRateRenderer(cell) {
      const value = cell.getValue();
      const { retainedColor } = useStarsFunnelColors();
      return <CellDisplayRate color={retainedColor} rate={value} />;
    },
  }),
  columnHelper.accessor("satisfactionRate", {
    header: "Satisfaction rate",
    cell: (cell) => {
      const value = cell.getValue();
      if (value == null) {
        return <NotAvailableCell />;
      }
      return (
        <SatisfactionRateProgress fontSize="sm" satisfactionRate={value} />
      );
    },
  }),
  columnHelper.accessor("feedbackCountAll", {
    header: "Feedback",
    cell: (cell) => {
      const value = cell.getValue();
      return (
        <CellDisplayText
          aria-label={`Based on ${value} submissions`}
          icon={<RiChat1Line size="16" />}
          text={value ? <CompactNumber value={value} /> : null}
        />
      );
    },
  }),
  columnHelper.accessor("averageFrequency", {
    header: "Frequency",
    cell: function AverageFrequencyRenderer(cell) {
      const value = cell.getValue();
      const { retainedColor } = useStarsFunnelColors();
      if (value === null) {
        return <NotAvailableCell />;
      }
      return (
        <MultiValueFlexContainer>
          <FrequencyProgress filledColor={retainedColor} frequency={value} />
        </MultiValueFlexContainer>
      );
    },
  }),
  columnHelper.accessor("source", {
    header: "Tracking source",
    cell: (cell) => {
      const value = cell.getValue();
      return (
        <CellDisplayText
          icon={<FeatureSourceIcon source={value} />}
          minW="140px"
          text={value == "event" ? "Event" : "Company attributes"}
        />
      );
    },
  }),
  columnHelper.accessor("autoFeedbackSurveysEnabled", {
    header: "Automated feedback surveys",
    cell: (cell) => {
      const value = cell.getValue();
      return (
        <CellDisplayText
          icon={
            <AutoFeedbackSurveys aria-hidden="true" height={18} width={18} />
          }
          text={value ? "Enabled" : null}
        />
      );
    },
  }),
  columnHelper.accessor("createdAt", {
    header: "Created",
    cell: (cell) => {
      const value = cell.getValue();
      return <TimestampCell value={value} />;
    },
  }),
];
